import { useStorage } from '@vueuse/core';

const STORAGE_KEY = 'client-settings';

interface IClientSettings {
	// settings
	highContrastMode: boolean
	grayscaleMode: boolean
	blackWhiteMode: boolean
	dyslexicMode: boolean
	largeTextMode: boolean
	boldTextMode: boolean
	underlineLinksMode: boolean
	animations: boolean
	// rating
	ratingWideMode: boolean
	ratingDeleteMode: boolean
	ratingViewMode: 'list' | 'grid'
	// challenges
	showCompletedChallenges: boolean
	showExpiredChallenges: boolean
	// currently reading
	currentlyReadingInputMode: 'page' | 'percentage'
	// wishlist
	wishlistViewMode: 'list' | 'grid'
	wishlistGridSize: 'small' | 'medium' | 'large'
	// my library
	myLibraryViewMode: 'list' | 'grid'
	myLibraryGridSize: 'small' | 'medium' | 'large'
	myLibraryShowReadBooks: boolean
	myLibraryShowDnfBooks: boolean
	myLibraryCategoryState: { [key: number]: boolean }
	// book search
	bookSearchViewMode: 'list' | 'grid'
	bookSearchGridSize: 'small' | 'medium' | 'large'
	// custom books
	customBooksShowBooks: boolean
	customBooksShowAudiobooks: boolean
}

const defaultClientSettings = (): IClientSettings => {
	return {
		highContrastMode: false,
		grayscaleMode: false,
		blackWhiteMode: false,
		dyslexicMode: false,
		largeTextMode: false,
		boldTextMode: false,
		underlineLinksMode: false,
		animations: true,
		ratingWideMode: false,
		ratingDeleteMode: false,
		ratingViewMode: 'grid',
		showCompletedChallenges: true,
		showExpiredChallenges: true,
		currentlyReadingInputMode: 'page',
		wishlistViewMode: 'grid',
		wishlistGridSize: 'large',
		myLibraryViewMode: 'grid',
		myLibraryGridSize: 'large',
		myLibraryShowReadBooks: true,
		myLibraryShowDnfBooks: true,
		myLibraryCategoryState: {},
		bookSearchViewMode: 'grid',
		bookSearchGridSize: 'medium',
		customBooksShowBooks: true,
		customBooksShowAudiobooks: true,
	};
};

export const useClientSettings = () => {
	const defaults = defaultClientSettings();
	const storage = useStorage<IClientSettings>(STORAGE_KEY, defaults, localStorage);

	// loop over all properties, if some are missing add the defaults
	for (const property of Object.keys(defaults)) {
		if ((<any>storage.value)[property] === undefined)
			(<any>storage.value)[property] = (<any>defaults)[property];
	}

	return storage;
};
